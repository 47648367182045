import { ArrowBackRounded } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
  Button,
} from "@mui/material";
import { ColDef } from "ag-grid-community";
import { useState } from "react";

interface FacetedFilterMenuProps<T> {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  columnFields: string[];
  columnDefs: ColDef<T>[];
  rowData: T[];
  onFilterChange: (field: string, values: Set<any>) => void;
  activeFilters: Map<string, Set<any>>;
}

/**
 * An optional filter menu for AgGridWrapper which autopopulates filterable values based on specified columns.
 * Note that it will NOT work with all data types, or complex rendered columns.
 * A user must be able to reasonably infer the value based on the data.
 * @param props - The props for the component.
 * @returns A faceted filter menu for filtering data in an AgGrid.
 */
export const FacetedFilterMenu = <T,>({
  anchorEl,
  onClose,
  columnFields,
  columnDefs,
  rowData,
  onFilterChange,
  activeFilters,
}: FacetedFilterMenuProps<T>) => {
  const [selectedColumn, setSelectedColumn] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState("");

  // Get unique values for a column
  const getUniqueValues = (field: string) => {
    const values = new Set<any>();
    rowData.forEach((row) => {
      const value = row[field as keyof T];
      if (value !== undefined && value !== null) {
        values.add(value);
      }
    });
    return Array.from(values).sort();
  };

  // Filter values based on search term
  const getFilteredValues = (field: string) => {
    const uniqueValues = getUniqueValues(field);
    if (!searchTerm) return uniqueValues;

    return uniqueValues.filter((value) =>
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  // Handle column selection
  const handleColumnClick = (field: string) => {
    setSelectedColumn(field);
  };

  // Handle value selection
  const handleValueToggle = (field: string, value: any) => {
    console.log("handleValueToggle", field, value);
    const currentValues = new Set(activeFilters.get(field) || []);
    if (currentValues.has(value)) {
      currentValues.delete(value);
    } else {
      currentValues.add(value);
    }
    onFilterChange(field, currentValues);
  };

  // Clear filters for a column
  const handleClearColumn = (field: string) => {
    onFilterChange(field, new Set());
  };

  // Clear all filters
  const handleClearAll = () => {
    columnFields.forEach((field) => {
      onFilterChange(field, new Set());
    });
  };

  // Updated to always return headerName or a formatted field name as fallback
  const getColumnLabel = (field: string) => {
    const column = columnDefs.find((col) => col.field === field);
    return column?.headerName || field.replace(/([A-Z])/g, " $1").trim(); // Formats camelCase to words
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      PaperProps={{
        sx: {
          maxHeight: 500,
          width: selectedColumn ? 400 : 300,
        },
      }}
    >
      <Box sx={{ p: 2 }}>
        <Typography fontWeight={500} sx={{ pl: 2 }}>
          Filter {selectedColumn ? getColumnLabel(selectedColumn) : "Columns"}
        </Typography>
        {selectedColumn && (
          <Button
            size="small"
            startIcon={<ArrowBackRounded />}
            onClick={() => {
              setSelectedColumn(null);
              setSearchTerm("");
            }}
            sx={{ textTransform: "none" }}
          >
            Back
          </Button>
        )}
      </Box>
      <Divider />

      {selectedColumn ? (
        <Box>
          <Box sx={{ p: 2 }}>
            <Stack spacing={2}>
              {/* I like the stock, smaller input here instead of the MUI one. Easier to work with. */}
              <input
                type="text"
                placeholder="Search values..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{
                  padding: "8px",
                  border: "1px solid #ddd",
                  borderRadius: "4px",
                  width: "100%",
                }}
              />
            </Stack>
          </Box>
          <Divider />
          <List sx={{ maxHeight: 400, overflow: "auto" }}>
            {getFilteredValues(selectedColumn).map((value) => (
              <MenuItem
                key={value}
                dense
                sx={{
                  py: 0.25,
                  "& .MuiFormControlLabel-label": {
                    fontSize: "0.875rem",
                    fontWeight: 500,
                  },
                }}
                onClick={() => handleValueToggle(selectedColumn, value)}
              >
                <FormControlLabel
                  // Prevents double-triggers on the list item click event.
                  onClick={(e) => e.preventDefault()}
                  control={
                    <Checkbox
                      checked={
                        activeFilters.get(selectedColumn)?.has(value) || false
                      }
                    />
                  }
                  label={value.toString()}
                />
              </MenuItem>
            ))}
          </List>
        </Box>
      ) : (
        <List dense>
          {columnFields.map((field) => {
            const activeCount = activeFilters.get(field)?.size || 0;
            return (
              <ListItem
                sx={{
                  "& .MuiListItemText-primary": {
                    fontWeight: 600,
                  },
                }}
                key={field}
                // Deprecated, but it's what AgGrid docs recommend and breaks click events without it.
                button
                onClick={() => handleColumnClick(field)}
                secondaryAction={
                  activeCount > 0 && (
                    <Button
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleClearColumn(field);
                      }}
                    >
                      Clear
                    </Button>
                  )
                }
              >
                <ListItemText
                  primary={getColumnLabel(field)}
                  secondary={
                    activeCount > 0
                      ? `${activeCount} filter${activeCount > 1 ? "s" : ""}`
                      : "No filters"
                  }
                />
              </ListItem>
            );
          })}
        </List>
      )}
    </Menu>
  );
};
