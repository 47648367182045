import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ErrorDialog from "../../../components/ErrorDialog";
import useCSVDownloader from "../../../helpers/hooks/useCsvDownloader";
import ExportDateRangeModal from "../../../components/ExportDateRangeModal";
import { StoreData } from "../types/StoreResponseData";
import getStoreExport from "../../../api/stores/getStoreExport";
import { Box, Button } from "@mui/material";
import { AgGridWrapper } from "../../../components/AgGridWrapper/AgGridWrapper";
import { FileUploadOutlined, OpenInBrowserOutlined } from "@mui/icons-material";
import dayjs from "dayjs";
import getAllStores from "../../../api/stores/getAllStores";
import { ColDef } from "ag-grid-community";
import getStoresByTeam from "../../../api/teams/getStoresByTeam";
import { useSnackbar } from "notistack";
import { ResponsiveContainerStyle } from "../../../helpers/generalUtilities";

interface StoresListPageProps {
  selectedTeam: string;
}

interface StoreDataColumn extends StoreData {
  Actions?: null;
}

function StoresListPage(props: StoresListPageProps) {
  // Util
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { downloadCSV, setStartDate, setEndDate, startDate, endDate } =
    useCSVDownloader(
      dayjs(Date.now()).subtract(1, "year").toDate(),
      dayjs(Date.now()).toDate()
    );

  // Destructuring
  const teamID = props.selectedTeam || null;

  // State
  const [stores, setStores] = useState<StoreData[]>([]);
  const [pageError, setPageError] = useState<string>("");
  const [storesUpToDate, setStoresUpToDate] = useState<boolean>(false);
  const [csvData, setCsvData] = useState<string | null>(null);
  const [isStoreExportModalOpen, setIsStoreExportModalOpen] =
    useState<boolean>(false);
  const [storeIdExport, setstoreIdExport] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [exportIsProcessing, setExportIsProcessing] = useState<boolean>(false);

  // AG Grid State
  const [colDefs] = useState<ColDef<StoreDataColumn>[]>([
    { field: "Name", flex: 2 },
    { field: "City", flex: 1 },
    { field: "State", flex: 1 },
    { field: "Country", flex: 1 },
    {
      field: "Actions",
      flex: 2,
      minWidth: 300,
      cellRenderer: (row: any) => (
        <>
          {/* Open */}
          <Button
            sx={{ mr: 2 }}
            size="small"
            variant="outlined"
            startIcon={<OpenInBrowserOutlined />}
            onClick={() => {
              onTeamSelected(row.data.StoreId);
            }}
          >
            Open
          </Button>{" "}
          <Button
            variant="outlined"
            startIcon={<FileUploadOutlined />}
            size="small"
            sx={{ mr: 2 }}
            onClick={() => getStoreIdandOpenModal(row.data.StoreId)}
          >
            Store Metrics (.csv)
          </Button>
        </>
      ),
    },
  ]);

  // Effects
  useEffect(() => {
    const fetchStoresList = async () => {
      try {
        setLoading(true);
        const data = await getStoresByTeam(teamID!);
        setLoading(false);
        if (data) {
          setStores(
            data
              .filter((store: StoreData) => store.StoreId !== null)
              .map((store: StoreData) => store)
          );
          setStoresUpToDate(true);
        }
      } catch (error: any) {
        enqueueSnackbar("Error", {
          variant: "error",
          cta: "Failed to load store list.",
        });
        console.log(error.message);
      }
    };
    fetchStoresList();
  }, [teamID, storesUpToDate]);

  useEffect(() => {
    if (!csvData) return;
    downloadCSV({ csvData: csvData, type: "store", id: storeIdExport! });

    setCsvData(null);

    return () => {
      setCsvData(null);
    };
  }, [csvData, downloadCSV, storeIdExport]);

  const handleStoreExport = async () => {
    if (teamID && storeIdExport && startDate && endDate) {
      try {
        setExportIsProcessing(true);
        const data = await getStoreExport(
          teamID,
          storeIdExport,
          startDate,
          endDate
        );
        if (data) {
          setCsvData(data);
          setIsStoreExportModalOpen(false);
        }
      } catch (error) {
        setIsStoreExportModalOpen(false);
        console.error("Error fetching CSV data:", error);
      } finally {
        setExportIsProcessing(false);
      }
    }
  };

  function onTeamSelected(teamId: string) {
    navigate({
      pathname: "./edit",
      search: "?store=" + teamId,
    });
  }

  function onCloseErrorDialog() {
    setPageError("");
  }

  function getStoreIdandOpenModal(storeId: string) {
    setstoreIdExport(storeId);
    setIsStoreExportModalOpen(true);
  }

  return (
    <>
      <Box sx={ResponsiveContainerStyle}>
        {/* Grid */}
        <AgGridWrapper
          facetedColumns={[ "City", "State", "Country"]}
          id="stores"
          columnDefs={colDefs}
          rowData={stores}
          quickSearch
          export
          loading={loading}
        />
      </Box>

      {/* Error Dialog */}
      <ErrorDialog
        errorMessage={pageError}
        open={pageError.length > 0}
        onClose={onCloseErrorDialog}
      />

      {/* Export Modal */}
      <ExportDateRangeModal
        open={isStoreExportModalOpen}
        setStartDate={(date: Date | null) => setStartDate(date)}
        setEndDate={(date: Date | null) => setEndDate(date)}
        startDate={startDate ? dayjs(startDate) : null}
        endDate={endDate ? dayjs(endDate) : null}
        handleClose={() => setIsStoreExportModalOpen(false)}
        handleExport={handleStoreExport}
        exportIsProcessing={exportIsProcessing}
      />
    </>
  );
}

export default StoresListPage;
