import {
  Box,
  Checkbox,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import postAssignUsertoStore from "../../../api/teams/postAssignUserToStore";
import { AgGridWrapper } from "../../../components/AgGridWrapper/AgGridWrapper";
import { useTeamStore } from "../../../stores/teams/TeamStore";
import { useSnackbar } from "notistack";
import { FileCopyRounded, HelpOutlineRounded } from "@mui/icons-material";
import useLayoutStore from "../../../stores/layout/LayoutStore";
import { ResponsiveContainerStyle } from "../../../helpers/generalUtilities";
import getAdminTeamMemberStores from "../../../api/users/getAdminTeamMemberStores";
import { AdminTeamMemberStore } from "../../team/types/TeamMemberStore";
import { blue, grey } from "@mui/material/colors";

const AdminUserEditPage = () => {
  // Util
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { roleOnTeam } = useTeamStore();
  const { setSubRoute } = useLayoutStore();

  // State
  const [userId, setUserId] = useState<string | null>(null);
  const [fullname, setFullname] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const [params] = useSearchParams();
  const [assignedStores, setAssignedStores] = useState<AdminTeamMemberStore[]>(
    []
  );
  const [storesChecked] = useState<{
    [key: string]: boolean;
  }>({});
  const [loading, setLoading] = useState<boolean>(false);

  // Effects
  useEffect(() => {
    let userId = params.get("userId");
    let fullname = params.get("fullname");
    let email = params.get("email");
    if (userId) {
      setUserId(userId);
      setFullname(fullname);
      setEmail(email);
    } else {
      navigate("../admin/users");
    }
  }, []);

  const fetchTeamMemberStores = async () => {
    setLoading(true);
    if (userId) {
      try {
        const data = await getAdminTeamMemberStores(userId);
        setSubRoute({
          title: `${fullname ?? email ?? ""}`,
        });
        setLoading(false);

        if (data) {
          setAssignedStores(data);
          data.forEach((store: AdminTeamMemberStore) => {
            storesChecked[store.StoreId] = !store.Deactivated;
          });
        }
      } catch (error) {
        setLoading(false);
        enqueueSnackbar("Failed to load stores", { variant: "error" });
      }
    }
  };

  useEffect(() => {
    if (userId) {
      fetchTeamMemberStores();
    }
  }, [userId]);

  async function onStoreCheckboxToggled(
    storeId: string,
    checked: boolean,
    teamId: string
  ) {
    let selectedStore: AdminTeamMemberStore | null = null;
    assignedStores.forEach((store) => {
      if (store.StoreId === storeId) {
        selectedStore = store;
      }
    });

    if (!selectedStore) {
      return;
    }

    const requestData = {
      TeamId: teamId,
      UserId: userId as string,
      StoreId: storeId,
      StoreAssignmentId: (selectedStore &&
        (selectedStore as AdminTeamMemberStore).StoreAssignmentId) as string,
      Notes: "",
      Deactivated: checked,
    };

    try {
      const data = await postAssignUsertoStore(requestData);
      if (data) {
        enqueueSnackbar(`${!checked ? "Activated" : "Deactivated"} Store.`, {
          variant: "success",
        });

        fetchTeamMemberStores();
      }
    } catch (error: any) {
      enqueueSnackbar(
        `Failed to ${!checked ? "Activate" : "Deactivate"} Store.`,
        {
          variant: "error",
        }
      );
    }
  }

  return (
    <Box sx={ResponsiveContainerStyle}>
      <Box sx={{ height: "100vh", mt: 2 }}>
        <AgGridWrapper
          id="admin-team-stores"
          columnDefs={[
            {
              headerName: "Store",
              field: "StoreName",
              flex: 1,
              minWidth: 200,
            },
            {
              headerName: "Team",
              field: "TeamName",
              flex: 1,
              minWidth: 200,
            },
            {
              headerName: "Active",
              field: "Deactivated",
              flex: 1,
              minWidth: 100,
              cellRenderer: (row: any) => {
                console.log(row);
                return (
                  <Box sx={{ width: "100%" }} alignItems="center">
                    <Checkbox
                      disabled={roleOnTeam !== "admin"}
                      checked={!row.data.Deactivated}
                      onClick={() =>
                        onStoreCheckboxToggled(
                          row.data.StoreId,
                          !row.data.Deactivated,
                          row.data.TeamId
                        )
                      }
                    />
                  </Box>
                );
              },
            },
          ]}
          rowData={assignedStores}
          loading={loading}
          quickSearch
          plugins={
            <Stack direction="row" gap={4} alignItems="center">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigator.clipboard.writeText(userId ?? "");
                  enqueueSnackbar("Invite Code Copied", { variant: "success" });
                }}
              >
                <FileCopyRounded fontSize="small" sx={{ color: grey[700] }} />
                <Typography
                  variant="overline"
                  sx={{
                    fontWeight: "400",
                    transition: "font-weight 0.1s ease-in-out",
                    ":hover": { fontWeight: "600" },
                    color: grey[700],
                  }}
                >
                  {userId ?? "No User ID Set"}
                </Typography>
              </Box>
              <Tooltip title="From this page you can activate or deactivate existing assignments for the user. To make a new store assignment, please use the 'My Teams' page.">
                <IconButton size="small">
                  <HelpOutlineRounded sx={{ color: blue[700] }} />
                </IconButton>
              </Tooltip>
            </Stack>
          }
          hidePagination
        />
      </Box>
    </Box>
  );
};

export default AdminUserEditPage;
