import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import postToggleUserActivation from "../../../api/teams/postToggleUserActivation";
import { LoadingButton } from "@mui/lab";
import { TeamMember } from "../../../stores/teams/TeamMemberStore";
import { enqueueSnackbar } from "notistack";

interface ToggleUserActivationModalProps {
  open: boolean;
  onClose: () => void;
  user: TeamMember | null;
  selectedTeam: string;
  reloadTeamMembers: () => void;
}

export const ToggleUserActivationModal = (
  props: ToggleUserActivationModalProps
) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleSubmit = async () => {
    setLoading(true);
    const requestData = {
      TeamId: props.selectedTeam,
      UserId: props.user?.UserId || "",
      Active: !props.user?.Active,
    };

    try {
      const data = await postToggleUserActivation(requestData);
      if (data) {
        setLoading(false);
        enqueueSnackbar("Status Updated", { variant: "success" });
        props.reloadTeamMembers();
        props.onClose();
      }
    } catch (error: any) {
      setErrorMessage(error.message);
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      <Dialog open={props.open} onClose={props.onClose}>
        <DialogTitle>
          {props.user && props.user.Active ? "Confirm Deactivation" : "Confirm Activation"}
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Are you sure you want to{" "}
            {props.user && props.user.Active ? "deactivate" : "activate"}{" "}
            <b>{props.user && props.user.Email}</b>?
          </Typography>
          {errorMessage && (
            <Alert sx={{ mt: 2 }} severity="error">
              {errorMessage}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose}>Cancel</Button>
          <LoadingButton
            loading={loading}
            color={props.user && props.user.Active ? "error" : "primary"}
            variant="contained"
            onClick={() => {
              handleSubmit();
            }}
          >
            {props.user && props.user.Active ? "Deactivate" : "Activate"}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
