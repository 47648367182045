import { Box, Button, Checkbox, Chip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ErrorDialog from "../../../components/ErrorDialog";
import {
  AddCircleOutline,
  CheckCircleRounded,
  DevicesRounded,
  FileCopyRounded,
  ManageAccounts,
  RadioButtonUncheckedRounded,
} from "@mui/icons-material";
import getUserTeams from "../../../api/teams/getUserTeams";
import { AgGridWrapper } from "../../../components/AgGridWrapper/AgGridWrapper";
import { formatDisplayDate } from "../../../components/AgGridWrapper/utilities";
import { ManageTeamMemberDialog } from "../components/ManageTeamMemberModal/ManageTeamMemberDialog";
import { OpenInBrowserOutlined } from "@mui/icons-material";
import { ToggleUserActivationModal } from "../components/ToggleUserActivationModal";
import { useTeamStore } from "../../../stores/teams/TeamStore";
import useTeamMemberStore, {
  TeamMember,
} from "../../../stores/teams/TeamMemberStore";
import { ColDef } from "ag-grid-community";
import { ResponsiveContainerStyle } from "../../../helpers/generalUtilities";
import { grey } from "@mui/material/colors";
import { enqueueSnackbar } from "notistack";
import { NoMaxWidthTooltip } from "../../admin/users/AdminUsersPage";
import { formatDistance } from "date-fns";

interface TeamMemberColumn extends TeamMember {
  Actions?: null;
}

const TeamIndex = (props: any) => {
  // Store
  const { roleOnTeam } = useTeamStore();

  // Prop Destructuring
  const selectedTeam = props.selectedTeam;

  // Util
  const navigate = useNavigate();
  const colDefs: ColDef<TeamMemberColumn>[] = [
    {
      field: "Email",
      headerName: "User Details",
      flex: 0.7,
      cellRenderer: (params: any) => (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography
            variant="body2"
            color={params.data.Fullname ? "inherit" : grey[400]}
          >
            {params.data.Fullname ?? "No Name Set"}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {params.data.Email}
          </Typography>
        </Box>
      ),
    },
    {
      field: "Role",
      flex: 0.3,
      cellRenderer: (data: any) => (
        <Chip size="small" label={data.value} variant="outlined" />
      ),
    },
    {
      field: "UserId",
      headerName: "Invite Code",
      flex: 1.0,
      cellRenderer: (params: any) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            cursor: "pointer",
          }}
          onClick={() => {
            navigator.clipboard.writeText(params.value);
            enqueueSnackbar("Invite Code Copied", { variant: "success" });
          }}
        >
          <FileCopyRounded fontSize="small" sx={{ color: grey[700] }} />
          <Typography
            variant="overline"
            sx={{
              fontWeight: "400",
              transition: "font-weight 0.1s ease-in-out",
              ":hover": { fontWeight: "600" },
              color: grey[700],
            }}
          >
            {params.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "AppVersion",
      headerName: "Devices",
      flex: 0.5,
      cellRenderer: (params: any) => {
        const devices = params.data.AppVersion;

        return (
          <NoMaxWidthTooltip
            title={
              <Typography
                component="div"
                variant="caption"
                style={{
                  whiteSpace: "pre",
                  maxHeight: "200px",
                  overflowY: "auto",
                }}
              >
                {devices.map((device: any, index: number) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      alignItems: "center",
                    }}
                    gap={2}
                  >
                    <span>{device.DeviceName}</span>
                    <Typography variant="caption" sx={{ opacity: 0.6 }}>
                      {`- ${device.AppVersion} -`}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{ opacity: 0.6, fontStyle: "italic" }}
                      fontSize={10}
                    >
                      {`Last updated ${formatDistance(
                        new Date(device.LastDateUpdated),
                        new Date()
                      )} ago.`}
                    </Typography>
                  </Box>
                ))}
              </Typography>
            }
            placement="top"
          >
            <Chip
              size="small"
              icon={<DevicesRounded />}
              label={
                <Box>
                  <b>{devices.length}</b>{" "}
                  {devices.length === 1 ? "Device" : "Devices"}
                </Box>
              }
            />
          </NoMaxWidthTooltip>
        );
      },
    },
    {
      field: "JoinedTeam",
      headerName: "Joined Team",
      cellRenderer: (data: any) => formatDisplayDate(data.value),
    },
    {
      field: "Active",
      sortable: false,
      flex: 0.2,
      cellRenderer: (row: any) => (
        <Box sx={{ width: "100%" }} alignItems="center">
          <Checkbox
            disabled={roleOnTeam !== "admin"}
            checked={row.data.Active}
            onClick={() => openToggleUserActivationModal(row.data)}
          />
        </Box>
      ),
    },
    {
      field: "Actions",
      sortable: false,
      flex: 0.5,
      minWidth: 230,
      cellRenderer: (row: any) => (
        <Box>
          <Button
            disabled={roleOnTeam !== "admin"}
            sx={{ mr: 2 }}
            size="small"
            variant="outlined"
            startIcon={<OpenInBrowserOutlined />}
            onClick={() => {
              openTeamMemberPage(row.data.UserId);
            }}
          >
            Open
          </Button>
          <Button
            disabled={roleOnTeam !== "admin"}
            sx={{ mr: 2 }}
            size="small"
            variant="outlined"
            startIcon={<ManageAccounts />}
            onClick={() => {
              openManageTeamModal("edit", row.data);
              console.log(row.data);
            }}
          >
            Permissions
          </Button>
        </Box>
      ),
    },
  ];

  const { teamMembers, fetchTeamMembers } = useTeamMemberStore();

  // State
  const [teamName, setTeamName] = useState<string | null>(null);
  const [createdDate, setCreatedDate] = useState<string | null>(null);
  const [pageError, setPageError] = useState<string>("");
  const [usersUpToDate, setUsersUpToDate] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [isManageTeamModalOpen, setIsManageTeamModalOpen] =
    useState<boolean>(false);
  const [isDeactivateUserModalOpen, setIsDeactivateUserModalOpen] =
    useState<boolean>(false);
  const [selectedTeamMember, setSelectedTeamMember] =
    useState<TeamMember | null>(null);
  const [manageType, setManageType] = useState<"add" | "edit" | null>(null);
  const [showInactiveUsers, setShowInactiveUsers] = useState<boolean>(false);

  // Effects
  useEffect(() => {
    const fetchUserTeams = async () => {
      if (!selectedTeam) {
        return;
      }
      try {
        setLoading(true);
        const data = await getUserTeams();
        if (data) {
          let foundTeam = false;

          data.forEach((team: any) => {
            if (team.TeamId === selectedTeam) {
              setTeamName(team.Name);
              setCreatedDate(team.TeamCreatedAt);
              foundTeam = true;
            }
          });

          if (!foundTeam) {
            setTeamName(null);
            setCreatedDate(null);
          }
        }
      } catch (error: any) {
        setPageError(error.message);
        console.log(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUserTeams();
    fetchTeamMembers(selectedTeam);
  }, [selectedTeam, usersUpToDate]);

  // Functions
  function onCloseErrorDialog() {
    setPageError("");
  }

  function openAddTeamMemberDialog() {
    setManageType("add");
    setIsManageTeamModalOpen(true);
  }

  function openTeamMemberPage(userId: string) {
    navigate({
      pathname: "./edit",
      search: "?userId=" + userId,
    });
  }

  function openManageTeamModal(type: "add" | "edit", teamMember?: TeamMember) {
    setManageType(type);
    if (teamMember) {
      setSelectedTeamMember(teamMember);
    }
    setIsManageTeamModalOpen(true);
  }

  function openToggleUserActivationModal(teamMember: TeamMember) {
    setSelectedTeamMember(teamMember);
    setIsDeactivateUserModalOpen(true);
  }

  return (
    <>
      <Box sx={ResponsiveContainerStyle}>
        <AgGridWrapper
          facetedColumns={["Role"]}
          rowHeight={52}
          id="team-members"
          rowData={teamMembers.filter(
            (member) => showInactiveUsers || member.Active
          )}
          columnDefs={colDefs}
          quickSearch
          rememberPageNumber
          gridTitle={teamName ?? ""}
          loading={loading}
          plugins={
            <>
              <Chip
                sx={{ mr: 4 }}
                label={
                  <>
                    <b>Created: </b>
                    {createdDate && formatDisplayDate(new Date(createdDate))}
                  </>
                }
              />
              <Chip
                icon={
                  showInactiveUsers ? (
                    <RadioButtonUncheckedRounded />
                  ) : (
                    <CheckCircleRounded />
                  )
                }
                sx={{ mr: 4 }}
                label={"Hide Inactive Users"}
                onClick={() => setShowInactiveUsers(!showInactiveUsers)}
                color={showInactiveUsers ? "default" : "primary"}
              />
              <Button
                variant="contained"
                onClick={openAddTeamMemberDialog}
                startIcon={<AddCircleOutline />}
                disabled={roleOnTeam !== "admin"}
              >
                Add User to Team
              </Button>
            </>
          }
        />
      </Box>

      <ManageTeamMemberDialog
        type={manageType!}
        teamId={selectedTeam}
        onClose={() => {
          setIsManageTeamModalOpen(false);
          setSelectedTeamMember(null);
        }}
        teamMember={selectedTeamMember!}
        open={isManageTeamModalOpen}
        teamName={teamName!}
        reloadTeamMembers={() => fetchTeamMembers(selectedTeam)}
      />

      {/* <ManageTeamMemberModal
        open={isManageUserModalOpen}
        onClose={() => {
            setIsManageUserModalOpen(false);
            setSelectedTeamMember(null);
          }
        }
        teamMember={selectedTeamMember!}
        teamId={selectedTeam}
        reloadTeamMembers={fetchTeamMembers}
      /> */}

      <ToggleUserActivationModal
        user={selectedTeamMember}
        open={isDeactivateUserModalOpen}
        onClose={() => {
          setIsDeactivateUserModalOpen(false);
          setSelectedTeamMember(null);
        }}
        reloadTeamMembers={() => fetchTeamMembers(selectedTeam)}
        selectedTeam={selectedTeam}
      />

      <ErrorDialog
        errorMessage={pageError}
        open={pageError.length > 0}
        onClose={onCloseErrorDialog}
      />
    </>
  );
};

export default TeamIndex;
