import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
} from "@mui/material";
import { AddCircleOutline, CloudUpload } from "@mui/icons-material";
import { AdminStoreData } from "./types/AdminStore";
import postAdminUpdateStore, { AdminCreateStoreRequestData } from "../../../api/stores/postAdminUpdateStore";
import { AgGridWrapper } from "../../../components/AgGridWrapper/AgGridWrapper";
import { ColDef } from "ag-grid-community";
import getAdminAllStores from "../../../api/stores/getAdminAllStores";
import AdminBulkPlanogramAssignDialog from "./components/AdminBulkPlanogramAssignDialog";
import { ResponsiveContainerStyle } from "../../../helpers/generalUtilities";
import AdminStoreFormDialog from "./components/AdminStoreFormDialog";
import { StoreFormInitialValues } from "./types/StoreFormInitialValues";
import { useSnackbar } from "notistack";

function AdminStoresListPage(props: any) {
  // Util
  const navigate = useNavigate();

  // Destructuring
  const teamID = props.selectedTeam || null;
  const { enqueueSnackbar } = useSnackbar();

  // State
  const [stores, setStores] = useState<AdminStoreData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isRequestLoading , setIsRequestLoading] = useState<boolean>(false);
  const [isCreateStoreDialogOpen, setIsCreateStoreDialogOpen] = useState<boolean>(false);
  const [storesUpToDate, setStoresUpToDate] = useState<boolean>(false);
  const [openBulkAssignment, setOpenBulkAssignment] = useState<boolean>(false);

  // AG Grid State
  const [colDefs] = useState<ColDef<AdminStoreData>[]>([
    { field: "Name", flex: 1 },
    { field: "City", flex: 1 },
    { field: "State", flex: 1 },
    { field: "Country", flex: 1 },
    { field: "StoreId", flex: 1 },
  ]);

  const fetchStores = async () => {
    if (teamID === null) {
      setStores([]);
      return;
    }

    if (storesUpToDate) {
      return;
    }

    try {
      setLoading(true);
      const data = await getAdminAllStores();

      setStores(
        data
          .filter((s) => s.StoreId !== null)
          .map((store: AdminStoreData) => {
            return store;
          })
      );
    } catch (err: any) {
      enqueueSnackbar("Error", {
        variant: "error",
        cta: "Failed to fetch stores.",
      });
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStores();
  }, [storesUpToDate]);

  function onTeamSelected(teamId: string) {
    navigate({
      pathname: "./edit",
      search: "?store=" + teamId,
    });
  }


  const handleCreateStore = async (formValues:StoreFormInitialValues) => {
    setIsRequestLoading(true);

    const requestData: AdminCreateStoreRequestData  = {
      Name: formValues.name,
      Address: formValues.address,
      AddressLine2: formValues.addressLine2 || null,
      City: formValues.city,
      Suburb: formValues.suburb || null,
      State: formValues.country === "New Zealand" ? null : formValues.state,
      Country: formValues.country,
      PostCode: formValues.postCode,
      Latitude: formValues.latitude || 0,
      Longitude: formValues.longitude || 0
    };

    try {
      const data = await postAdminUpdateStore(requestData);
      if (data) {
        setIsRequestLoading(false);
        setIsCreateStoreDialogOpen(false);
        fetchStores();
        enqueueSnackbar("Success", {
          variant: "success",
          cta: "Store created successfully.",
        });
        setStoresUpToDate(false);
      }
    } catch (error: any) {
      setIsRequestLoading(false);
      enqueueSnackbar("Error", {
        variant: "error",
        cta: "Failed to create store.",
      });
      console.log(error.message);
    }
  };

  return (
    <Box sx={ResponsiveContainerStyle}>
      <AgGridWrapper
        id="admin-stores"
        columnDefs={colDefs}
        facetedColumns={["City", "State", "Country"]}
        rowData={stores}
        quickSearch
        export
        rememberPageNumber
        gridTitle="Unassigned Stores"
        onRowClicked={(row: any) => onTeamSelected(row.data.StoreId)}
        loading={loading}
        plugins={
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Button
              sx={{ mr: 1 }}
              variant="contained"
              onClick={() => setOpenBulkAssignment(true)}
              startIcon={<CloudUpload />}
            >
              Bulk Planogram Assignment
            </Button>
            <Button
              variant="contained"
              onClick={()=> setIsCreateStoreDialogOpen(true)}
              startIcon={<AddCircleOutline />}
            >
              Add New Store
            </Button>
          </Box>
        }
      />

      <AdminStoreFormDialog
        mode="create"
        open={isCreateStoreDialogOpen}
        handleClose={() => setIsCreateStoreDialogOpen(false)}
        loading={isRequestLoading}
        handleSubmit={handleCreateStore}
      />

      <AdminBulkPlanogramAssignDialog
        onClose={() => setOpenBulkAssignment(false)}
        open={openBulkAssignment}
      />

    </Box>
  );
}

export default AdminStoresListPage;
